import React, { ReactNode } from 'react'
import { ChipProps, Paper, Stack, Typography } from '@mui/material'
import StoryCard from 'components/StoryCard'
import AppIcon from 'components/AppIcon'
import StoryProgressModel from 'models/StoryProgress'
import {
  PatientTodoCategory,
  PatientTodoStatus,
} from '__generated__/graphql'
import { AnimatePresence, motion } from 'framer-motion'
import { noop } from 'lodash'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'

export type TodoListItem = {
  category: keyof typeof PatientTodoCategory
  chip?: {
    label?: ChipProps['label']
    icon?: ChipProps['icon']
    color?: ChipProps['color']
  }
  hidden?: boolean | ((storyProgress: StoryProgressModel) => boolean)
  href?: string
  id: string
  title: string
  description: string
  status: keyof typeof PatientTodoStatus
}

// TODO Add inline right arrow to todo titles
const CategoryIcons = {
  [PatientTodoCategory.APPOINTMENT]: AppIcon.Appointment,
  [PatientTodoCategory.LEARN]: AppIcon.Learn,
  [PatientTodoCategory.SURVEY]: AppIcon.Questionnaire,
}

export type TodoListProps = {
  items: TodoListItem[]
  children?: ReactNode
}

export default function TodoList(props: TodoListProps) {
  const { items } = props

  return (
    <Stack
      sx={{
        alignItems: 'center',
        color: 'text.secondary',
        my: -2,
      }}
    >
      {!items.length && (
        <TodoItem key="todo.empty">
          <Paper
            sx={{
              borderLeftWidth: {
                sm: 1,
                xs: 0,
              },
              borderRadius: {
                sm: 1,
                xs: 0,
              },
              borderRightWidth: {
                sm: 1,
                xs: 0,
              },
              px: 2,
              py: 4,
            }}
            elevation={0}
          >
            <Stack
              sx={{
                gap: 1,
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Stack
                sx={{
                  alignItems: 'center',
                  width: 1,
                }}
              >
                <AppIcon.EmptyTasks
                  sx={{
                    height: 1,
                    maxHeight: 200,
                    maxWidth: 200,
                    width: 1,
                  }}
                />
              </Stack>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ lineHeight: 1.5 }}
              >
                <strong>All good here!</strong>
                <br />
                We&apos;ll reach out when something is needed.
              </Typography>
            </Stack>
          </Paper>
        </TodoItem>
      )}
      <AnimatePresence>
        {items.map((item, index) => (
          <TodoItem key={item.id} index={index}>
            <StoryCard
              data-testid={`task-card.${index}`}
              chip={item.chip}
              href={item.href}
              id={item.id}
              title={item.title}
              subtitle={item.description}
              startAdornment={
                <TodoListIcon category={item.category} />
              }
              endAdornment={
                <ArrowForwardRoundedIcon fontSize="small" />
              }
              sx={{
                my: {
                  sm: 0,
                  xs: '-9px',
                },
                position: 'relative',
                top: {
                  sm: 0,
                  xs: 9,
                },
              }}
            />
          </TodoItem>
        ))}
        {props.children && (
          <TodoItem key="todo.children" index={items.length}>
            {props.children}
          </TodoItem>
        )}
      </AnimatePresence>
    </Stack>
  )
}

function TodoItem(props: { children: ReactNode; index?: number }) {
  return (
    <motion.div
      style={{ padding: '8px 0', width: '100%' }}
      layout
      animate={{ opacity: 1, scale: 1 }}
      initial={{ opacity: 0, scale: 0.9 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{
        delay: (props.index ?? 0) * 0.05,
      }}
      onUpdate={noop} // TODO temp fix to avoid framer-motion broken hardware-accelerated opacity animation.
    >
      {props.children}
    </motion.div>
  )
}

function TodoListIcon(props: { category: TodoListItem['category'] }) {
  const Icon = CategoryIcons[props.category]
  return <Icon color="primary" />
}
