import React from 'react'
import { Stack } from '@mui/material'
import EnhancedDrawer from 'components/EnhancedDrawer'
import TodoList, { TodoListItem } from 'components/TodoList'

export type CompletedTasksDrawerProps = {
  items: TodoListItem[]
  onClose: () => void
  open: boolean
}

export default function CompletedTasksDrawer(
  props: CompletedTasksDrawerProps,
) {
  const { items, ...drawerProps } = props

  if (!items.length) {
    return null
  }

  return (
    <EnhancedDrawer
      {...drawerProps}
      title="Completed To-dos"
      dismiss
      PaperProps={{
        sx: {
          '& .MuiCard-root': {
            mx: {
              sm: 2,
              xs: 0,
            },
          },
          height: 'fit-content',
        },
      }}
    >
      <Stack
        sx={{
          gap: 2,
          minHeight: 300,
          mt: -2,
          mx: -2,
          pb: 7,
        }}
      >
        <TodoList items={items} />
      </Stack>
    </EnhancedDrawer>
  )
}
