import { AppContext } from 'components/AppContext'
import {
  getStoryParamsFromPath,
  getStoryUrl,
} from 'components/StoryPlayer'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useState } from 'react'
import SettingsStore from 'stores/SettingsStore'

let hasInitiated = false

export default function useOnboarding() {
  const router = useRouter()
  const { patient, featureFlags } = useContext(AppContext)
  const { storyProgress } = useContext(StoryPlayerContext)
  const { enablePatientOnboardingAlwaysOnStart, onboardingStoryId } =
    featureFlags
  const [onboardingCompleted, setOnboardingCompleted] =
    useState(false)
  const result = useMemo(
    () => ({
      onboardingCompleted,
      onboardingStoryId,
    }),
    [onboardingCompleted, onboardingStoryId],
  )

  const pageHasStoryDeeplink = !!getStoryParamsFromPath(router.asPath)
    ?.storyId

  useEffect(() => {
    if (!hasInitiated && storyProgress) {
      const onboardingCompleted =
        // The `enablePatientOnboardingAlwaysOnStart` flag prevents onboarding from completing.
        enablePatientOnboardingAlwaysOnStart
          ? false
          : !!SettingsStore.get('settings.onboardingCompleted')

      setOnboardingCompleted(onboardingCompleted)
      // Open story player with valid patient with incomplete onboarding - assuming there's no deeplink for another story.
      if (
        !onboardingCompleted &&
        patient &&
        !pageHasStoryDeeplink &&
        onboardingStoryId
      ) {
        const url = getStoryUrl(onboardingStoryId)
        router.replace(url, url, { scroll: false }).catch((e) => {
          // Avoids Cancel rendering route errors.
          // Workaround for https://github.com/vercel/next.js/issues/37362
          if (!e.cancelled) {
            throw e
          }
        })

        storyProgress.on('complete', (e) => {
          if (
            // The `enablePatientOnboardingAlwaysOnStart` flag prevents onboarding from completing.
            !enablePatientOnboardingAlwaysOnStart &&
            e.storyId === onboardingStoryId
          ) {
            SettingsStore.set('settings.onboardingCompleted', true)
            setOnboardingCompleted(true)
          }
        })
      }
    }
    hasInitiated = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enablePatientOnboardingAlwaysOnStart,
    onboardingStoryId,
    storyProgress,
    pageHasStoryDeeplink,
  ])

  return result
}
