import { inRange } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

type TimeOfDay = 'morning' | 'afternoon' | 'evening'
export default function useTimeOfDay(): TimeOfDay {
  const currentHour = new Date().getHours()
  const [hour, setHour] = useState(new Date().getHours())
  const timeOfDay = useMemo<TimeOfDay>(
    () =>
      inRange(hour, 3, 12)
        ? 'morning'
        : inRange(hour, 12, 17)
          ? 'afternoon'
          : 'evening',
    [hour],
  )

  useEffect(() => setHour(currentHour), [currentHour])

  return timeOfDay
}
