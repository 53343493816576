import { useMemo, useState } from 'react'

export default function useModalProps() {
  const [open, setOpen] = useState(false)
  const result = useMemo(
    () => ({
      modalProps: {
        onClose: () => setOpen(false),
        open,
      },
      open,
      setOpen,
    }),
    [open],
  )
  return result
}
