import React, { ReactNode, useContext } from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardProps,
  Chip,
  ChipProps,
  Stack,
  Typography,
} from '@mui/material'
import { isFunction } from 'lodash'
import StoryProgressModel from 'models/StoryProgress'
import Link from 'next/link'
import { StoryPlayerContext } from 'components/StoryPlayerContext'

export type StoryCardProps = {
  id: string
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  hidden?: boolean | ((storyProgress: StoryProgressModel) => boolean)
  href?: string
  title?: ReactNode
  subtitle?: ReactNode
  chip?: {
    label?: ChipProps['label']
    icon?: ChipProps['icon']
    color?: ChipProps['color']
  }
  sx?: CardProps['sx']
}

export default function StoryCard(
  props: StoryCardProps,
): JSX.Element {
  const { storyProgress } = useContext(StoryPlayerContext)
  const { title, subtitle, startAdornment, endAdornment } = props
  const hidden = isFunction(props.hidden)
    ? props.hidden(storyProgress)
    : props.hidden

  if (hidden) {
    return null
  }

  return (
    <Box
      id={`card-${props.id}`}
      sx={{ '& a': { textDecoration: 'none' }, position: 'relative' }}
    >
      <Card
        sx={{
          borderLeftWidth: {
            sm: 1,
            xs: 0,
          },
          borderRadius: {
            sm: 2,
            xs: 0,
          },
          borderRightWidth: {
            sm: 1,
            xs: 0,
          },
          color: 'text.primary',
          display: 'flex',
          flexDirection: 'column',
          flexShrink: 0,
          justifyContent: 'center',
          overflow: 'visible',
          position: 'relative',
          width: 'auto',
          ...props.sx,
        }}
      >
        <CardActionArea
          LinkComponent={Link}
          href={props.href}
          sx={{
            borderRadius: {
              sm: 2,
              xs: 0,
            },
          }}
        >
          <CardContent
            sx={{
              '&:last-child': {
                pb: 2,
              },
              display: 'flex',
              flexDirection: 'column',
              height: 1,
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Stack
              sx={{
                alignItems: 'flex-start',
                flexDirection: 'row',
                gap: 1.5,
                justifyContent: 'space-between',
              }}
            >
              {startAdornment && (
                <Stack
                  className="startAdornment"
                  sx={{
                    // alignSelf: 'stretch',
                    // justifyContent: 'center',
                    p: 0.25,
                  }}
                >
                  {startAdornment}
                </Stack>
              )}
              <Stack
                sx={{
                  alignItems: 'flex-start',
                  gap: 0.25,
                  width: 1,
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    alignSelf: 'stretch',
                    gap: 1,
                  }}
                >
                  <Typography
                    component="div"
                    variant="h5"
                    hidden={!title}
                    sx={{
                      fontWeight: 500,
                      width: 1,
                    }}
                  >
                    {title}
                  </Typography>
                  {props.chip && (
                    <Stack
                      sx={{
                        alignItems: 'flex-end',
                        flexGrow: 1,
                      }}
                    >
                      <Chip size="small" {...props.chip} />
                    </Stack>
                  )}
                </Stack>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    color: 'text.secondary',
                    mt: 0.5,
                    width: 1,
                  }}
                  hidden={!subtitle}
                >
                  {subtitle}
                </Typography>
              </Stack>
              {endAdornment && (
                <Stack
                  className="endAdornment"
                  sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'center',
                  }}
                >
                  {endAdornment}
                </Stack>
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  )
}
