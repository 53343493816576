import React, { useRef, useState } from 'react'
import {
  Box,
  Grid,
  Grow,
  Paper,
  Slide,
  Stack,
  Typography,
} from '@mui/material'
import MarkdownContent from 'components/MarkdownContent'

export type ContentHeaderProps = {
  subtitle?: string
  title: string
  logoSrc?: string
}
export default function ContentHeader(
  props: ContentHeaderProps,
): JSX.Element {
  const titleContainer = useRef<HTMLDivElement>()
  const [logoLoaded, setLogoLoaded] = useState(false)

  return (
    <Paper
      sx={{
        borderLeftWidth: {
          sm: 1,
          xs: 0,
        },
        borderRadius: {
          sm: 1,
          xs: 0,
        },
        borderRightWidth: {
          sm: 1,
          xs: 0,
        },
        mx: {
          sm: 2,
        },
        px: 2,
        py: 2,
      }}
    >
      <Grid
        container
        component="header"
        gap={2}
        sx={{
          flexWrap: 'nowrap',
          minHeight: 64,
        }}
      >
        <Grid
          item
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: 64,
            width: 1,
          }}
        >
          <Typography
            hidden={!props.subtitle}
            variant="h5"
            sx={{ opacity: 0.66 }}
            color="text.secondary"
          >
            {props.subtitle}
          </Typography>
          <Box
            ref={titleContainer}
            sx={{
              minHeight: 22,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Slide
              in={!!props.title}
              direction="up"
              container={titleContainer.current}
              mountOnEnter
            >
              <Typography
                variant="h4"
                sx={{
                  '& span': {
                    display: 'inline-block',
                  },
                  '& u': {
                    '&:after': {
                      backgroundColor: 'primary.main',
                      borderRadius: 4,
                      bottom: -2,
                      content: '""',
                      display: 'block',
                      height: 3,
                      left: 0,
                      overflow: 'hidden',
                      position: 'absolute',
                      width: 1,
                    },
                    display: 'inline-block',
                    position: 'relative',
                    textDecoration: 'none',
                  },
                  fontSize: {
                    sm: '1.25rem',
                    xs: '1.125rem',
                  },
                  lineHeight: 1.2,
                }}
              >
                <MarkdownContent value={props.title} />
              </Typography>
            </Slide>
          </Box>
        </Grid>
        {props.logoSrc && (
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              maxWidth: 240,
            }}
          >
            <Grow in={logoLoaded}>
              <Stack
                component="img"
                src={props.logoSrc}
                onLoad={() => setLogoLoaded(true)}
                sx={{
                  maxHeight: 80,
                  maxWidth: 240,
                  objectFit: 'contain',
                  width: 1,
                }}
              />
            </Grow>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}
